import React from "react";
import { ListGroup, Spinner, Alert, Card } from "react-bootstrap";
import { useChannelStore } from "~/stores/channel-store";
import ResponsiveImage from "~/components/responsive-image";
import styles from "~/main.module.css";
import { useFetchImages } from "~/images/hooks/use-fetch-images";

const ImageList: React.FC = () => {
    const { currentChannel: channelId } = useChannelStore();
    if (!channelId) {
        return <Alert variant="danger">Channel ID is missing from the store.</Alert>;
    }

    const { data: images, isLoading, isError } = useFetchImages(channelId);

    if (isLoading) return <Spinner animation="border" />;
    if (isError) return <Alert variant="danger">Something went wrong...</Alert>;

    return (
        <div className="container">
            <h1 className="my-4">Image List</h1>
            <ListGroup>
                {images?.map((image: { id: string; url: string; createdAt?: string }) => (
                    <ListGroup.Item key={image.id}>
                        <div>{`Image ID: ${image.id}`}</div>
                        {image.createdAt &&
                            <Card.Text>Created At: {new Date(image.createdAt).toLocaleString()}</Card.Text>}
                        <ResponsiveImage className={styles.logo}
                                         alt="RenewYou"
                                         basePath={`/public-images/${channelId}/`}
                                         src={image.id}/>
                    </ListGroup.Item>
                ))}
            </ListGroup>
        </div>
    );
};

export default ImageList;