import ResponsiveImage from "~/components/responsive-image";
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styles from "./home.module.css";
import {useAuth} from "~/AuthContext";

export default function Home() {

    const { isAuthenticated } = useAuth();

    const myLibraryButtonUrl = isAuthenticated ? "/api/authenticated/redirect/channel-redirect" : "/login";

    return (
        <>
            <div className={styles.content}>
                <div className={styles.leftMain}>
                    <h1 className={styles.heading}>Your <br/>Gateway to Wellness<br/> Mastery</h1>
                    <p className={styles.info}>The most secure streaming platform for Health and Wellness content
                        creators.</p>
                    <div className={styles.buttonGroup}>
                        <a href={`${myLibraryButtonUrl}`}>
                            <Button variant="primary" className={styles.button}>My Library</Button>
                        </a>
                        <div style={{display: "flex"}}>
                            <p style={{paddingTop: "1rem"}}>Don't have an account?</p>
                        </div>
                        <Link to="/signup">
                            <Button variant="success" className={styles.button}>Sign Up</Button>
                        </Link>
                    </div>
                </div>
                <div className={styles.rightMain}>
                    <ResponsiveImage alt="Photo of a woman singing in front of a teacher on a TV."
                                     className={styles.mainImage}
                                     basePath="images"
                                     src="sing"/>
                </div>
            </div>
        </>
    );
}