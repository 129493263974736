import React from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { useAuth } from '~/AuthContext';

interface PrivateRouteProps {
    children: React.ReactNode;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
    const { channelId } = useParams<{ channelId: string }>();
    const { isAuthenticated, isLoading } = useAuth();

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (!isAuthenticated) {
        const callback = channelId ? `channel/${channelId}` : window.location.pathname.substring(1);
        return <Navigate to={`/login?callback=${callback}`} />;
    }

    return <>{children}</>;
};

export default PrivateRoute;