import { useQuery } from 'react-query';

const fetchUserEnrolledChannels = async (): Promise<any[]> => {
    const response = await fetch('/api/authenticated/channels/user-enrolled', {
        credentials: 'include',
    });
    if (!response.ok) {
        throw new Error('Failed to fetch user enrolled channels');
    }
    return response.json();
};

export const useUserEnrolledChannels = () => {
    return useQuery('userEnrolledChannels', fetchUserEnrolledChannels);
};