import React, {ReactElement, useState} from "react";
import Button from 'react-bootstrap/Button';
import Form from "react-bootstrap/Form";
import { useNavigate } from 'react-router-dom';
import formStyles from "../styles/form.module.css"
import {Alert} from "react-bootstrap";
import useRequestReset from "./useRequestReset";
import {RequestResetResponse, RequestResetFormData} from "./useRequestReset";

interface ResponseMessage {
    message: string;
    variant: "danger" | "success";
}

export default function RequestReset() : ReactElement {
    const [responseMessage, setResponseMessage] = React.useState< ResponseMessage | null>(null);
    const [formData, setFormData] = useState<RequestResetFormData>({
        email: '',
    });

    const navigate = useNavigate();

    const requestResetMutation = useRequestReset({
        onSuccess: function () {
            setResponseMessage({message: "Check your email for a link to reset your password.", variant: "success"});
            navigate("/login");
        },
        onError: function (response: RequestResetResponse) {
            if (response.status === 429) {
                setResponseMessage({message: "Too many request have been sent. Please try again later.", variant: "danger"});
            } else {
                setResponseMessage({message: "Sorry there was an error requesting your password reset. Please try again later.", variant: "danger"});
            }

        }
    });

    const handleResetRequest = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        requestResetMutation.mutateAsync(formData);
    };

    const handleChange = (e: any) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    return (
            <Form className={formStyles.form} onSubmit={handleResetRequest}>
                <h2>Reset your password</h2>
                <Form.Group>
                    <Form.Label>Email:</Form.Label>
                    <Form.Control required type="email" name="email" value={formData.email} onChange={handleChange}/>
                </Form.Group>
                <Button variant="primary"
                        type="submit">{requestResetMutation.isLoading ? 'Loading...' : 'Send Reset Email'}</Button>
                <br />
                {responseMessage && <Alert variant={responseMessage.variant}>{responseMessage.message}</Alert>}
            </Form>
    );
}
