import React from 'react';
import {Dropdown} from 'react-bootstrap';
import styles from "./main.module.css";
import {Row} from "react-bootstrap";
import ResponsiveImage from "./components/responsive-image";
import {Link, Outlet, useParams} from "react-router-dom";
import {useUserEnrolledChannels} from "~/hooks/api/authenticated/channels/use-user-enrolled-channels";
import LoginButton from "./components/login-button";
import headerLogoStyles from '~/header-logo.module.css';

export const Footer = () =>
    <footer className={styles.footer}>
        <span className={styles.footer}>© {new Date().getFullYear()} RenewYou</span>
        <Link to="/">Home</Link>
        <Link to="/contact-us">Contact Us</Link>
        <Link to="/catalog">Catalog</Link>
    </footer>;

export const AuthLayout = () => (
    <div>
        <section className={styles.authLayoutWrapper}>
            <Row>
                <header className={styles.logoWrapper}>
                    <Link to="/">
                        <ResponsiveImage className={styles.authLayoutLogo}
                                         alt="RenewYou Logo"
                                         basePath="/images"
                                         src="renewyou-logo"
                        />
                    </Link>
                </header>
            </Row>
            <Row>
                <main className={styles.main}><Outlet/></main>
            </Row>
        </section>
        <Footer/>
    </div>
);

interface SimpleLayoutProps {
    headerText?: string;
    linkToCatalog?: boolean;
}

export const SimpleLayout: React.FC<SimpleLayoutProps> = ({headerText}) => {
    const {channelId} = useParams<{ channelId: string }>();
    let { data: channels } = useUserEnrolledChannels();
    channels = channels?.filter((channel: any) => channel.channelId !== channelId);

    return <>
        <header className={styles.simpleLayoutHeader}>
            <nav className={styles.simpleLayoutHeaderTextWrapper}>
                {!channelId && <Link to="/">
                    <ResponsiveImage className={styles.logo}
                                     alt="RenewYou"
                                     basePath="/images"
                                     src="renewyou-logo"/>
                </Link>}
                {channelId && (
                    channels && channels.length > 0 ? (
                        <Dropdown>
                            <Dropdown.Toggle as="a">
                                <img className={headerLogoStyles.headerLogo} alt={`${channelId} Logo`} src={`/course-info/${channelId}/channel-logo-light.png`}/>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                {channels.map((channel: any) => (
                                    <Dropdown.Item key={channel.channelId} as={Link} to={`/channel/${channel.channelId}`}>
                                        {channel.title}
                                    </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    ) : ( <img className={headerLogoStyles.headerLogo}
                                 alt={`${channelId} Logo `}
                                 src={`/course-info/${channelId}/channel-logo-light.png`}/>
                    )
                )}
                {headerText && <h1 className={styles.headerText}>{headerText}</h1>}
            </nav>

            <LoginButton/>
        </header>
        <main><Outlet/></main>
        <Footer/>
    </>
};

interface RenewYouLayoutProps {
    mainStyle?: React.CSSProperties;
}

export const RenewYouLayout: React.FC<RenewYouLayoutProps> = ({mainStyle}) => {
    return (
        <>
            <header className={styles.renewYouHeader}>
                <Link to="/">
                    <ResponsiveImage
                        className={styles.renewYouLogo}
                        alt="RenewYou Logo"
                        basePath="/images"
                        src="renewyou-logo"
                    />
                </Link>
                <LoginButton/>
            </header>
            <main style={mainStyle}>
                <Outlet/>
            </main>
            <Footer/>
        </>
    );
};