import { useNavigate } from "react-router-dom";
import { Button, Dropdown } from 'react-bootstrap';

import { useAuth } from '../AuthContext';
import { useChannelStore } from '~/stores/channel-store';

import styles from './login-button.module.css';
import { useContentCreatorChannels } from "~/hooks/use-content-creator-channels";

const LoginButton: React.FC = () => {
    const { login, logout, isAuthenticated, user, isLoading } = useAuth();
    const navigate = useNavigate();

    const handleLoginLogout = async () => {
        if (isAuthenticated) {
            await logout();
        } else {
            login();
        }
    };

    const { currentChannel } = useChannelStore();
    const { data: channels } = useContentCreatorChannels();
    const myLibraryButtonUrl = isAuthenticated ? "/api/authenticated/redirect/channel-redirect" : "/login";

    return (
        !isLoading && (
            isAuthenticated ? (
                <Dropdown>
                    <Dropdown.Toggle style={{ display: "flex", alignItems: "center" }}
                                     variant="info"
                                     id="dropdown-basic">
                        <span className={styles.email}>{user?.firstName + " " + user?.lastName}</span>
                        <div className="caret"></div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className={styles.customDropdownMenu}>
                        {channels && channels.length > 0 && (
                            <>
                                {currentChannel && <>
                                    <Dropdown.Item as="a" href={myLibraryButtonUrl}>My Library</Dropdown.Item>
                                    <Dropdown.Item onClick={() => navigate('/assets/videos/upload')}>Upload</Dropdown.Item>
                                    <Dropdown.Item onClick={() => navigate('/assets/videos/list')}>My Videos</Dropdown.Item>
                                    <Dropdown.Item onClick={() => navigate('/assets/images/list')}>My Images</Dropdown.Item>
                                </>}
                            </>
                        )}
                        <Dropdown.Item onClick={handleLoginLogout}>Logout</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            ) : (
                <Button onClick={handleLoginLogout}>
                    Login
                </Button>
            )
        ) || null
    );
};

export default LoginButton;