import React from 'react';
import { Link, Outlet } from 'react-router-dom';
import { useChannelStore } from '~/stores/channel-store';
import styles from './main.module.css';
import LoginButton from './components/login-button';
import ResponsiveImage from './components/responsive-image';
import ChannelSelect from "~/components/channel-select";
import {Footer} from "~/layout";

interface SimpleLayoutProps {
    headerText?: string;
    linkToCatalog?: boolean;
}

export const ChannelStoreLayout: React.FC<SimpleLayoutProps> = ({ headerText }) => {
    const { currentChannel: channelId } = useChannelStore();

    return (
        <>
            <header className={styles.simpleLayoutHeader}>
                <nav className={styles.simpleLayoutHeaderTextWrapper}>
                    {!channelId && <Link to="/">
                        <ResponsiveImage className={styles.logo}
                                         alt="RenewYou"
                                         basePath="/images"
                                         src="renewyou-logo" />
                    </Link>}
                    <ChannelSelect />
                    {headerText && <h1 className={styles.headerText}>{headerText}</h1>}
                </nav>
                <LoginButton />
            </header>
            <main>
                <Outlet />
            </main>
            <Footer/>
        </>
    );
};