import React from 'react';
import {Alert, Button, Form} from 'react-bootstrap';
import useVerifyEmail from './useVerifyEmail';
import {useNavigate} from "react-router-dom";
import formStyles from "../styles/form.module.css";
import useResendCode, {ResendCodeResponse} from "./useResendCode";

interface ResponseMessage {
    message: string;
    variant: "danger" | "success";
}

const VerifyEmailForm: React.FC = () => {
    const [code, setCode] = React.useState<string>('');
    const [responseMessage, setResponseMessage] = React.useState< ResponseMessage | null>(null);

    const verifyEmailMutation = useVerifyEmail({
        onSuccess: function (response: { redirectUrl: string; }) {
            window.location.href = response.redirectUrl;
        },
        onError: function () {
            setResponseMessage({message: 'Failed to verify email', variant: "danger"});
        }
    });

    const urlParams = new URLSearchParams(window.location.search);
    const email = urlParams.get('email') as string;

    const navigate = useNavigate();

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        await verifyEmailMutation.mutateAsync({
            email,
            code,
        });
    };

    const resendCodeMutation = useResendCode({
        onSuccess: function () {
            setResponseMessage({message: "Your new code should arrive in your email shortly.", variant: "success"});
        },
        onError: function (response: ResendCodeResponse) {
            const status : number = response.status;
            switch(status) {
                case 429:
                    setResponseMessage({message: "Too many request have been sent. Please try again later.", variant: "danger"});
                    break;
                case 400:
                    navigate("/login");
                    break;
                default:
                    setResponseMessage({message: "Sorry there was an error resending code. Please try again later.", variant: "danger"});
                    break;
            }
        }
    });
    const handleResendCode = () => {
            resendCodeMutation.mutateAsync({email});
    };

    return (
        <Form className={formStyles.form} onSubmit={handleSubmit}>
            <h2>Verify your email</h2>
            <Form.Group controlId="formEmail">
                <p style={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap"
                }}>
                    <span>Enter the code sent to &nbsp;</span>
                    <b>{email}</b>
                    <span>The email may take few minutes to arrive.</span>
                </p>
                <p style={{
                    display: "flex",
                    alignItems: "center",
                }}>Not your email<Button variant="link" href='/login'>Back to Login</Button></p>
            </Form.Group>
            <Form.Group controlId="formVerificationCode">
                <Form.Label>Verification Code</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Enter verification code"
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                    required
                />
            </Form.Group>
            <Button variant="primary" type="submit" disabled={verifyEmailMutation.isLoading}>
                {verifyEmailMutation.isLoading ? 'Verifying...' : 'Verify'}
            </Button>
            <Button variant="link" onClick={handleResendCode}>{resendCodeMutation.isLoading ? 'Loading...' : 'Resend Code'}</Button>
            {responseMessage && <Alert variant={responseMessage.variant}>{responseMessage.message}</Alert>}
        </Form>
    );
};

export default VerifyEmailForm;
