import {ReactElement, FormEvent, useState} from "react";
import {useNavigate} from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Form from "react-bootstrap/Form";
import formStyles from "../styles/form.module.css";
import {Alert} from "react-bootstrap";
import {Variant} from "react-bootstrap/types";
import useReCaptcha from '../hooks/use-recaptcha';

type CodeTypes = "registrationSuccess" | "loginFailed";

interface MessageCode {
    message: string;
    variant: Variant;
}

interface Codes {
    registrationSuccess: MessageCode;
    loginFailed: MessageCode;
    sessionTimedOut: MessageCode;
    alreadyHasAccess: MessageCode;
    recaptchaFailure: MessageCode;
}

const codes: Codes = {
    registrationSuccess: {
        message: "Registration successful. Please login.",
        variant: "success"
    },
    loginFailed: {
        message: "Invalid Username and or Password!",
        variant: "danger"
    },
    sessionTimedOut: {
        message: "Your session has timed out and you need to login again.",
        variant: "warning"
    },
    alreadyHasAccess: {
        message: "Login to view your course.",
        variant: "info"
    },
    recaptchaFailure: {
        message: "Verification unsuccessful. Please refresh the page and try again.",
        variant: "danger"
    }
}

export default function EmailPassword(): ReactElement {
    const navigate = useNavigate();
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code') as CodeTypes;
    const callback = urlParams.get('callback');
    const channel = urlParams.get('channel');
    const course = urlParams.get('course');
    const {verifyRecaptcha} = useReCaptcha();
    const [isSubmitting, setIsSubmitting] = useState(false);

    let callbackInput = callback;
    if (course && channel && callback) {
        callbackInput = `${callback}/${channel}/${course}`;
    }

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsSubmitting(true);

        try {
            const token = await verifyRecaptcha('login');
            // Create a hidden input element to hold the reCAPTCHA token
            const hiddenInput = document.createElement('input');
            hiddenInput.type = 'hidden';
            hiddenInput.name = 'recaptchaToken';
            hiddenInput.value = token;

            // Append the hidden input to the form
            const form = e.target as HTMLFormElement;
            form.appendChild(hiddenInput);

            // Submit the form
            form.submit();
        } catch (error) {
            setIsSubmitting(false);
            console.error('reCAPTCHA verification failed', error);
        }
    };

    return (
        <>
            {code && <Alert variant={codes[code].variant}>{codes[code].message}</Alert>}
            <Form className={formStyles.form} method="post" action={"/api/auth/login"} onSubmit={handleSubmit}>
                <h2>Login</h2>
                <Form.Group>
                    <Form.Label htmlFor="email">Email</Form.Label>
                    <Form.Control
                        type="email"
                        name="email"
                        id="email"
                        autoCapitalize="off"
                        autoComplete="username"
                        autoFocus
                        required
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label htmlFor="password">Password</Form.Label>
                    <Form.Control
                        type="password"
                        name="password"
                        id="password"
                        autoCapitalize="off"
                        autoComplete="current-password"
                        required
                    />
                </Form.Group>
                <Form.Group style={{display: "flex", justifyContent: "space-between", marginTop: "1rem"}}>
                    <Button style={{marginTop: "1rem"}}
                            variant="primary"
                            type="submit"
                            disabled={isSubmitting}>
                        Login
                    </Button>
                </Form.Group>
                <Form.Group>
                    <p>Don't have an account?</p>
                    <Button variant="success"
                            type="button"
                            onClick={() => {
                                navigate("/signup")
                            }}>
                        Sign Up
                    </Button>
                </Form.Group>
                <Form.Group>
                    <Button style={{marginTop: "1rem"}}
                            variant="warning"
                            type="button"
                            onClick={() => {
                                navigate("/request-password-reset")
                            }}>
                        Reset your password
                    </Button>
                </Form.Group>
                {callbackInput && (
                    <Form.Control
                        type="hidden"
                        name="callback"
                        value={callbackInput}
                    />
                )}
            </Form>
        </>
    );
}