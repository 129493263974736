import { useQuery } from 'react-query';
import { useAuth } from '../AuthContext';

const fetchContentCreatorChannels = async () => {
    const response = await fetch('/api/authenticated/channels/content-creator', {
        credentials: 'include',
    });
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    return response.json() as Promise<Channel[]>;
};

export const useContentCreatorChannels = () => {
    const { isAuthenticated } = useAuth();

    return useQuery<Channel[], Error>(
        ['contentCreatorChannels'],
        fetchContentCreatorChannels,
        {
            enabled: isAuthenticated, // Only fetch if the user is authenticated
        }
    );
};

interface Channel {
    channelId: string;
    title: string;
}