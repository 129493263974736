import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { useContentCreatorChannels } from '~/hooks/use-content-creator-channels';
import { useChannelStore } from '~/stores/channel-store';
import headerLogoStyles from '~/header-logo.module.css';

const ChannelSelect: React.FC = () => {
    const { data: channels } = useContentCreatorChannels();
    const { currentChannel, setCurrentChannel } = useChannelStore();

    const handleSelect = (eventKey: string | null) => {
        if (eventKey) {
            setCurrentChannel(eventKey);
        }
    };

    const filteredChannels = channels?.filter(channel => channel.channelId !== currentChannel);

    return (
        <Dropdown onSelect={handleSelect}>
            <Dropdown.Toggle as="a">
                <img className={headerLogoStyles.headerLogo} alt="Channel Logo" src={`/course-info/${currentChannel}/channel-logo-light.png`} />
            </Dropdown.Toggle>

            <Dropdown.Menu>
                {filteredChannels && filteredChannels.map((channel) => (
                    <Dropdown.Item key={channel.channelId} eventKey={channel.channelId}>
                        {channel.title}
                    </Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default ChannelSelect;