// hooks/use-fetch-course.ts
import { useState, useEffect } from 'react';

interface BundleOffer {
    url: string;
    logoPath: string;
    text: string;
}

interface Course {
    playList: Array<any>;
    image: string;
    description: string;
    price: number;
    promotionPrice: string;
    normalPrice: number;
    isPackage: boolean;
    secondLogo: string;
    showBundleOffer: boolean;
    bundleOffer: BundleOffer;
    title: string;
}

const useFetchCourse = (channelId?: string, courseId?: string) => {
    const [courseData, setCourseData] = useState<Course | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        if (channelId && courseId) {
            const fetchCourse = async () => {
                try {
                    const response = await fetch(`/api/authenticated/courses/course-info/${channelId}/${courseId}`,
                        {            credentials: 'include'}
                    );
                    if (!response.ok) {
                        throw new Error('Failed to fetch course data');
                    }
                    const data = await response.json();
                    setCourseData(data);
                } catch (err) {
                    if (err instanceof Error) {
                        setError(err.message);
                    } else {
                        setError('An unknown error occurred');
                    }
                } finally {
                    setLoading(false);
                }
            };

            fetchCourse();
        }
    }, [channelId, courseId]);

    return { courseData, loading, fetchCourseError: error };
};

export default useFetchCourse;